<template>
  <div class="row">
    <div class="col-12 col-lg-5 c" v-if="current.status">
        <div class="card card-body">
            <div :class="'alert g text-center alert-' + current.color"><h3>{{ current.status }}</h3>
                <h2 v-if="current.name != ''">
                    {{ current.name }}
                </h2><br>
                <span v-html="current.number"></span>
            </div>
        </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-5 c g">
        <div class="card card-body" style="padding:0px;">
            <input type="text" class="form-control form-control-lg" v-model="value" placeholder="اكتب رقم الهوية هنا..." style="text-align: center;">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="checkBarcode()">
                انصراف الان
            </button>
            <br><br>
            خدمة الإستئذان: هي تسجيل حالة إنصراف بدون الالتزام بمواعيد الانصراف.
        </div>
    </div>
    <div class="attendsearchbyname" style="position: fixed; left: 0px; top: 100px">
        <button style="border-radius: 0px;" class="btn btn-sm btn-success" @click="byname = !byname">
            <i class="fa fa-search"></i>
            بحث بالاسم
        </button>
        <div class="card card-body" v-if="byname" style="width: 300px">
            <input type="text" class="form-control form-control-sm" v-model="name" placeholder="ابحث هنا...">
            <table class="table table-sm table-bordered table-hover">
                <tbody>
                    <template v-for="s in students">
                        <tr :key="s._id" v-if="s.name.includes(name) && name != ''" @click="value = s.number;checkBarcode()">
                            <td>{{ s.name }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            logs: [],
            value: null,
            last_value: null,
            students: [],
            groups: [],
            current: {},
            user: JSON.parse(localStorage.getItem('user')),
            notes: {},
            exnotes: {},
            byname: false,
            name: "",
            print: {
                "1": localStorage.getItem('print_1') == 'yes' ? true : false,
                "2": localStorage.getItem('print_2') == 'yes' ? true : false,
                "4": localStorage.getItem('print_4') == 'yes' ? true : false,
                "ex": localStorage.getItem('print_ex') == 'yes' ? true : false,
                "printmsg": localStorage.getItem("print_printmsg") ?? ""
            },
        }
    },
    beforeDestroy(){
    },
    created(){
        if(!checkPer("students|scan|endex")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        //
        $.post(api + '/user/students/groups', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.groups = r.response
            }
        }).fail(function(){
        })
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })

        $.post(api + '/user/students/notes/list', {
            jwt: this.user.jwt,
            active: true
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    if(!g.notes[a.number] && a.alert){
                        g.notes[a.number] = [];
                    }
                    if(!g.exnotes[a.number] && a.exalert){
                        g.exnotes[a.number] = [];
                    }
                    if(a.alert){
                        g.notes[a.number].push(a);
                    }
                    if(a.exalert){
                        g.exnotes[a.number].push(a);
                    }
                })
            }
        }).fail(function(){
            g.loading = false;
        })
        g.uploadLogs()
    },
    methods: {
        checkBarcode(){
            var g = this;
            var a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
            var value = a2e(g.value);
            g.value = null;
            g.last_value = null;
            var c = {}
            g.students.forEach(function(a){
                if(a.number == value){
                    c = a
                }
            })
            g.current = {}
            if(!c.name){
                g.current = {
                    status: "الطالب غير موجود",
                    name: "",
                    color: 'danger',
                    number: value
                }
            }else{
                var gr = {}
                g.groups.forEach(function(a){
                    if(a._id == c.group_id){
                        gr = a;
                    }
                })
                if(!gr._id){
                    g.current = {
                        status: "الطالب ليس لديه مجموعة اعدادات",
                        name: "",
                        color: 'danger',
                        number: value
                    }
                }else{
                    var weekday = new Array(7);
                    weekday[0] = "Sunday";
                    weekday[1] = "Monday";
                    weekday[2] = "Tuesday";
                    weekday[3] = "Wednesday";
                    weekday[4] = "Thursday";
                    weekday[5] = "Friday";
                    weekday[6] = "Saturday";
                    if (
                    gr.days
                        .split(",")
                        .includes(weekday[new Date().getDay()])
                    ) {

                        var ada = new Date().toISOString().split("T")[0] + " ";

                         var sstatus = 4;
                        var sstatustext = "انصراف";
                        g.logs.unshift({
                            name: c.name,
                            number: c.number,
                            time: ada +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[0] +
                            ":" +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[1],
                            status: sstatus,
                            sstatustext: sstatustext,
                            endex: "yes"
                        });
                        localStorage.setItem("reports", JSON.stringify(g.logs));
                        g.current = {
                            status: sstatustext,
                            name: c.name,
                            color: sstatus.toString().replace("0", "secondary")
                            .toString().replace("1", "success")
                            .toString().replace("2", "warning text-dark")
                            .toString().replace("3", "danger")
                            .toString().replace("4", "success"),
                            number: value
                        }
                        if(g.exnotes[value]){
                            if(g.exnotes[value].length){
                                g.current = {
                                status: sstatustext,
                                name: c.name,
                                color: "info",
                                number: "<h3><ul>"+g.exnotes[value].map(function(r){
                                    return "<li>"+r.content+"</li>"
                                }).join("")+"</ul></h3>"
                            }
                            }
                        }
                        if(g.print["ex"]){
                            g.printJoin(c, "ex", ada +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[0] +
                            ":" +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[1])
                        }
                    }else{
                        g.current = {
                            status: "هذا اليوم غير مسموح بالفحص فيه",
                            name: "",
                            color: 'danger',
                            number: value
                        }
                    }
                }
            }
            setTimeout(() => {
                g.current = {}
            }, 5000);
        },

        printJoin(student, status, time){
        var w = window.open('auto:blank', 'tahdir.net', 'width=200,height=300');
            w.document.write(`
        <!DOCTYPE html>
        <html lang="ar" dir="rtl">

        <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css2?family=Almarai&display=swap" rel="stylesheet">

        <style>
                html, body {
                border-bottom: 1px solid black;
                margin: 0 !important; 
                padding: 0 !important;
                overflow: hidden;
                }
            @media print {

                html, body {
                border-bottom: 1px solid black;
                margin: 0 !important; 
                padding: 0 !important;
                overflow: hidden;
                }

                }
        table {
            width: 95%;
        }

        td {
            font-size: 10px;
            font-family: 'Almarai', sans-serif;
            font-weight: bold;

        }

        td {
            padding: 5px;
        }

        tr {
            border: 1px solid;
        }

        th,
        td {
            border: 1px solid #ddd;
        }

        h2 {
            font-family: 'Almarai', sans-serif;
        }
        </style>
        </head>

        <body>
        <div class="container">
            <center>
                <p>${this.user.name}</p>
            </center>
            <table class="table text-left">
                <tr>
                    <td>الإسم</td>
                    <td>${student.name}</td>
                </tr>
                <tr>
                    <td>الصف</td>
                    <td>${student.classname}</td>
                </tr>
                <tr>
                    <td>الفصل</td>
                    <td>${student.classroom}</td>
                </tr>
                <tr>
                    <td>الحالة</td>
                    <td>${status?.toString()?.replace('1', "حضور").replace('2', 'تأخر')?.replace("3", 'غياب')?.replace("4", "انصراف")?.replace("ex", "استئذان")}</td>
                </tr>
                <tr>
                    <td>التوقيت</td>
                    <td>${time}</td>
                </tr>
            </table>
            <center>
                <small>بواسطة <u>التحضير الذكي - tahdir.net</u></small>
            </center>
            <br><br>
        </div>
        </body>

        </html>
        `)
            w.document.close()
            w.print()
            setTimeout(() => {
                w.close()
            }, 1000);
        },
        uploadLogs(){
            var g = this;
            if(g.logs.length){
                $.post(api + '/user/students/reports/upload', {
                    jwt: this.user.jwt,
                    reports: JSON.stringify(g.logs.map(function(x){
                        return {
                            number: x.number,
                            time: x.time,
                            from: 'endex',
                            endex: x.endex
                        }
                    })),
                }).then(function(r){
                    if(r.status == 100){
                        g.logs = [];
                        localStorage.removeItem('reports');
                    }
                    setTimeout(() => {
                        g.uploadLogs()
                    }, 10000);
                }).fail(function(){
                    setTimeout(() => {
                        g.uploadLogs()
                    }, 10000);
                })
            }else{
                setTimeout(() => {
                    g.uploadLogs()
                }, 10000);
            }
        }
    }
}
</script>

<style>

</style>