<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card">
            <div class="card-body">
                <div class="form-group">
                  <h5 for="">الرسالة <a href="javascript:;" @click="templates('asd43345')"><u><i class="fa fa-comment"></i> قوالب الرسائل</u></a></h5>
                  <textarea class="form-control asd43345" v-model="message" rows="5" placeholder="اكتب الرسالة هنا..."></textarea>
                </div>
                <div class="form-group">
                  <label for="">ارفاق ملف</label>
                  <input type="file"
                    class="form-control" id="ingredient_file" accept=".png,.jpeg,.jpg,.pdf,.docx,.doc,.xls,.xlsx" @change="uploadImage()">
                    <strong v-if="loading" id="helpId" class="form-text text-success">
                        <img :src="require('@/assets/images/loading.svg')" style="width: 20px;" alt=""> جاري الرفع...
                    </strong>
                </div>
                <div class="g">
                    <div class="border g">
                        <small>المتغيرات في الرسالة</small>
                        <br>
                        اسم الطالب: {name} <br> رابط الملف: {link}
                        <br>
                        الصف: {classname} <br> الفصل: {classroom}
                        <br>
                        رابط تحميل تطبيق ولي الامر للاندرويد: <input type="text" style="border:none; width: 300px" readonly value="https://bit.ly/tahdirparenta">
                        <br>
                        رابط تحميل تطبيق ولي الامر للايفون: <input type="text" style="border:none; width: 300px" readonly value="https://bit.ly/4fxEYDI">
                        <br>
                        <button class="btn btn-sm btn-outline-primary" @click="copy(); donecopy = true">
                            <i class="fa fa-copy"></i>
                            {{ donecopy ? 'تم النسخ' :  'نسخ المتغيرات' }}
                        </button>&nbsp;
                        <button class="btn btn-sm btn-outline-success" @click="insert()">
                            <i class="fa fa-arrow-up"></i>
                            اضافة للرسالة
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div v-if="!way">
                    <p>اختر المراد الارسال لهم</p>
                    <div class="row">
                        <div class="col-12 col-lg-4 g">
                            <button class="btn btn-block btn-primary" @click="way = 'single'">
                                <i class="fa fa-users"></i>
                                اختر فردياً
                            </button>
                        </div>
                        <div class="col-12 col-lg-4 g">
                            <button class="btn btn-block btn-success" @click="way = 'bulk'">
                                <i class="fa fa-users"></i>
                                اختر من الفصول
                            </button>
                        </div>
                        <div class="col-12 col-lg-4 g">
                            <button class="btn btn-block btn-info" @click=" getReport(); way = 'attend';">
                                <i class="fa fa-check"></i>
                                الحاضرين اليوم
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="way == 'single' || way == 'attend'">
                    <div class="g">
                        اختر الطلاب <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" @keyup="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button>
                    </div>
                    <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
                        <div class="form-check" v-for="student in students" :key="student._id">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="selected" :value="student">
                            {{ student.name }}
                        </label>
                        </div>
                    </div>
                </div>
                <div v-if="way == 'bulk'">
                    <div>
                        اختر الفصول <a href="javascript:;" @click="selected_classrooms = classrooms; classChanges()">
                            <i class="fa fa-check-square"></i>
                            اختيار الكل
                        </a>
                        <br><br>
                    </div>
                    <div class="form-check" v-for="c in classrooms" :key="c">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" @change="classChanges(c)" v-model="selected_classrooms" :value="c">
                            {{ c }}
                        </label>
                        <div class="card-body">
                            <div class="border g" style="overflow-y: scroll; height: 100px">
                                <template v-for="student in students">
                                    <div v-if="student.classname + ' - ' + student.classroom == c" class="form-check" :key="student._id">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" v-model="selected" :value="student">
                                            {{ student.name }}
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="way && selected.length > 0">
                <div class="row">
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                            <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block x345" @click="send('wa')">
                            <i class="fa fa-whatsapp"></i>
                            اداة المتصفح
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-primary btn-block"  @click="send('sms')">
                            <i class="fa fa-mobile"></i>
                            SMS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            message: "",
            loading: false,
            file: null,
            user: JSON.parse(localStorage.getItem('user')),
            way: false,
            students: [],
            classrooms: [],
            q: null,
            selected: [],
            selected_classrooms: [],
            donecopy: false
        }
    },
    created(){
        if(!checkPer("students|students|send")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getStudents()
    },
    methods: {
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    g.students.forEach(function(a){
                        if(!g.classrooms.includes(a.classname + ' - ' + a.classroom)){
                            g.classrooms.push(a.classname + ' - ' + a.classroom)
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        templates(x){
            $("#templates").click()
            $("#inserttemplateto").val(x)
        },
        classChanges(cc){
            var g = this;
            g.students.forEach(function(a){
                if(g.selected_classrooms.includes(a.classname + ' - ' + a.classroom) && cc == a.classname + ' - ' + a.classroom){
                    g.selected.push(a)
                }
                if(!g.selected_classrooms.includes(a.classname + ' - ' + a.classroom)){
                    g.selected = g.selected.filter(function(x){
                        if(!g.selected_classrooms.includes(x.classname + ' - ' + x.classroom)){
                            return false;
                        }else{
                            return x;
                        }
                    })
                }
            })
        },
        copy(){
            navigator.clipboard.writeText(`اسم الطالب: {name}
رابط الملف: {link}
الصف: {classname}
الفصل: {classroom}
رابط تحميل تطبيق ولي الامر للاندرويد: 
https://bit.ly/tahdirparenta
رابط تحميل تطبيق ولي الامر للايفون: 
https://bit.ly/4fxEYDI`);
        },
        insert(){
            this.message = (`اسم الطالب: {name}
رابط الملف: {link}
الصف: {classname}
الفصل: {classroom}
رابط تحميل تطبيق ولي الامر للاندرويد: 
https://bit.ly/tahdirparenta
رابط تحميل تطبيق ولي الامر للايفون: 
https://bit.ly/4fxEYDI`);
        },
        send(method){
            var g = this;
            var messages = [];
            g.selected.forEach(function(a){
                messages.push({
                    phones: a.phones,
                    message: g.message.replace("{name}", a.name).replace("{classname}", a.classname).replace("{classroom}", a.classroom).replace("{link}", `({${g.file}})`)
                })
            })
            if(g.file && messages[0]?.message?.includes("({")){
                $.post(api + '/user/general/short-links', {
                    jwt: this.user.jwt,
                    arr: JSON.stringify(messages),
                }).then(function(rx){
                    if(method == 'wa'){
                        $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                        $("#sendmodalwatype").val("students")
                        $("#sendWAModal").click()
                        $(".x345").removeClass('btn-success')
                        $(".x345").addClass('btn-outline-danger')
                        $(".x345").html("<i class='fa fa-refresh'></i> حدث الصفحة للارسال من الاداة مرة أخرى")
                    }else{
                        if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                        $.post(api + '/user/messages/send-arr', {
                            jwt: g.user.jwt,
                            arr: JSON.stringify(rx.response),
                            method: method,
                            type: "students"
                        }).then(function(r){
                            g.loaded = true;
                            if(r.status != 100){
                                alert(r.response, 200)
                            }else{
                                alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            }
                        }).fail(function(){
                            alert("حدث خطأ", 200)
                        })
                    }
                }).fail(function(){
                        alert("حدث خطأ.", 200)
                    })
            }else{
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(messages))
                    $("#sendmodalwatype").val("students")
                    $("#sendWAModal").click()
                        $(".x345").removeClass('btn-success')
                        $(".x345").addClass('btn-outline-danger')
                        $(".x345").html("<i class='fa fa-refresh'></i> حدث الصفحة للارسال من الاداة مرة أخرى")
                }else{
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(messages),
                        method: method,
                        type: "students"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }
        },
        getReport(){
            var g = this;
            $.post(api + '/user/students/reports/daily', {
                jwt: this.user.jwt,
                date: new Date().toISOString().split('T')[0],
                classname: null,
                status: null,
                q: null
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    var arr = r.response.map(function(x){
                        if(x.status == "1" || x.status == "2"){
                            return x.number;
                        }else{
                            return false;
                        }
                    }).filter(function(x){
                        return x;
                    })
                    g.students.forEach(function(a){
                        if(arr.includes(a.number)){
                            g.selected.push(a)
                        }
                    })
                }
            }).fail(function(){
                setTimeout(function(){
                    g.getReport()
                }, 500)
            })  
        },
        uploadImage(){
            var g = this;
            g.loading = true;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    g.loading = false;
                    // your callback here
                    if(data.status != 100){
                        alert("هذا الملف غير غير", 200)
                        return;
                    }
                    g.file = data.response
                    if(!g.message.includes("{link}")){
                    g.message = g.message + '\nرابط الملف: {link}'
                    }
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
        }
    }
}
</script>

<style>

</style>