<template>
    <div class="col-12 col-lg-6 g c">
        <div class="card card-body" v-if="current.name">
            <div class="media">
                <a class="align-self-center d-lg-flex d-none" href="javascript:;">
                    <img v-if="!settings.calls_image || !current.image" :src="require('@/assets/images/bell.gif')" style="width: 200px; border-radius: 50%">
                    <img v-if="settings.calls_image && current.image" :src="current.image" style="width: 200px; height: 200px; border-radius: 10px">
                </a>
                <div class="media-body align-self-center" style="padding-right: 40px">
                    <h1>{{ current.name }}</h1>
                    <h4>{{ current.classroom }}</h4>
                </div>
            </div>
        </div>
            <button class="btn btn-primary btn-sm" style="border-radius: 0px; padding: 5px" @click="print()">
                <i class="fa fa-print"></i> طباعة الجدول
            </button>
            <button class="btn btn-success btn-sm" style="border-radius: 0px; padding: 5px" @click="excel()">
                <i class="fa fa-download"></i> تصدير الجدول
            </button>
        <div class="table-responsive card" style="min-height: auto !important" id="ttable">
            <table class="table table-bordered table-hover">
                <thead>
                    <th style="background: #fff !important">
                        م
                    </th>
                    <th style="background: #fff !important">
                        طالب
                    </th>
                    <th style="background: #fff !important">
                        الفصل
                    </th>
                    <th style="background: #fff !important">
                        التوقيت
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(c, i) in calls" :key="c._id">
                        <td>{{ i+1 }}</td>
                        <td>
                            {{ c.name }}
                        </td>
                        <td>
                            {{ c.classroom }}
                        </td>
                        <td>
                            {{ c.date.split(" ")[1] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
var XLSX = require("xlsx");
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            current: {},
            calls: [],
            interval: null,
            last_name: null,
            settings: {},
            key: "kvfbSITh"
        }
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval);
        }
    },
    created(){
        if(!checkPer("calls")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.fetchCalls();
        g.interval = setInterval(() => {
            g.fetchCalls()
        }, 5000);
        $.post(api + '/user/calls/settings', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.settings = r.response
                if(g.settings.key){
                    g.key = g.settings.key
                }
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200)
        })
    },
    methods: {
        excel(){
            const wb = XLSX.utils.book_new(), g = this;
                const Heading = [
                    [
                        "م",
                        "الاسم",
                        "الفصل",
                        "التوقيت"
                    ]
                ];
                var xx = [];
                g.calls.forEach(function(a, i){
                    xx.push([
                        i+1,
                        a.name,
                        a.classroom,
                        a.date.split(" ")[1]
                    ])
                })
                
                // creating sheet and adding data from 2nd row of column A.
                // leaving first row to add Heading
                const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                    
                // adding heading to the first row of the created sheet.
                // sheet already have contents from above statement.
                XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                    
                // appending sheet with a name
                XLSX.utils.book_append_sheet(wb, ws, 'Records');
                    
                const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
        },
        playSound(){
            var audio = new Audio('/assets/sounds/bell.mp3');
            audio.play();
            var g = this;
            setTimeout(function(){
                if(g.settings.calls_voice){
                    var audio = new Audio('https://texttospeech.responsivevoice.org/v1/text:synthesize?text='+encodeURI(g.current.name)+'&lang=ar&engine=g3&name=&pitch=0.5&rate=0.5&volume=1&key='+g.key+'&gender=' + g.settings.calls_voice_gender);
                    audio.play();
                }
            }, 500)
        },
        fetchCalls(){
            var g = this;
            g.current = {}
            $.post(api + '/user/calls/history', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    if(r.response.next){
                        g.current = r.response.next
                        g.playSound()
                    }
                    g.calls = r.response.calls
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
        print(){
            var g = this;
            var g = this;
            var divToPrint=document.getElementById("ttable");
            var newWin= window.open("");
            newWin.document.write(`
            <html dir='rtl'>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
                    <title>التحضير الذكي - tahdir.net</title>
                    <style>
                        table, td, th {
                            border: 1px solid #9999 !important;
                            padding-right: 2px;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table, table *{
                            white-space: normal;
                        }
                        .hidemex{
                            display:none;
                        }
                        th{
                            background: #E7FBE6 !important
                        }
                    </style>
                </head>
                <body>
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-6 c text-center'>
<div style='border: dashed 2px #999; padding: 0px; border-radius: 10px; text-align: center'><h5>
تقرير المناداة بتاريخ <strong>${new Date().toISOString().split("T")[0]}</strong>
</h5></div><br>
                            </div>
                            <div class='col-12'>
                            
                        ${divToPrint.innerHTML}
                        </div>
                        </div>
                    </div>
                </body>
            </html>
            `);
            newWin.document.close()
            newWin.print();
            newWin.close();
        }
    }
}
</script>

<style>

</style>