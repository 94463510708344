<template>
  <b-card title="تعديل مجموعة اعدادات" class="col-12 col-lg-6 c">
    <div>
      <div ref="alert"></div>
      <div class="form-group">
        <label for=""><h5>عنوان المجموعة</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          :value="group.title"
          ref="title"
        />
      </div>
      <div class="col-12 g">
        <div class="card card-bod">
          <div class="row">
            <div class="col-12 g">
              <strong> أيام الحضور في المدرسة: </strong>
            </div>
            <div
              class="col-12 col-lg-3 g"
              v-bind:key="day.en"
              v-for="day in days"
            >
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    ref="days"
                    :value="day.en"
                    v-if="group.days.split(',').includes(day.en)"
                    checked
                  />
                  <input
                    type="checkbox"
                    class="form-check-input"
                    ref="days"
                    :value="day.en"
                    v-if="!group.days.split(',').includes(day.en)"
                  />
                  <span>
                    {{ day.ar }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-lg-6">
          <label for="">توقيت بداية احتساب الحضور</label>
          <input
            type="time"
            class="form-control"
            ref="start_date"
            :value="group.start_date"
            required
          />
          <small id="helpId" class="form-text text-muted"
            >الوقت الذي سيبدأ بعده احتساب الحضور.</small
          >
        </div>
        <div class="form-group col-12 col-lg-6">
          <label for="">توقيت بداية احتساب التأخر</label>
          <input
            type="time"
            class="form-control"
            ref="late_date"
            :value="group.late_date"
            required
          />
          <small id="helpId" class="form-text text-muted"
            >الوقت الذي سيبدأ بعده احتساب التأخر.</small
          >
        </div>
        <div class="form-group col-12 col-lg-6">
          <label for="">توقيت بداية احتساب الغياب</label>
          <input
            type="time"
            class="form-control"
            ref="ghiab_date"
            :value="group.ghiab_date"
            required
          />
          <small id="helpId" class="form-text text-muted"
            >الوقت الذي سيبدأ بعده احتساب الغياب.</small
          >
        </div>
        <div class="form-group col-12 col-lg-6">
          <label for="">توقيت بداية احتساب الانصراف</label>
          <input
            type="time"
            class="form-control"
            ref="end_date"
            :value="group.end_date"
            required
          />
          <small id="helpId" class="form-text text-muted"
            >الوقت الذي سيبدأ بعده احتساب الانصراف.</small
          >
        </div>
        <div class="col-12 g">
          <div class="form-check">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" v-model="group.second_leave" :value="true" ref="second_leave">
              احتساب البصمة الثانية بعد الحضور انصراف <strong>من البصمة فقط</strong>.
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button
          class="btn btn-success"
          style="margin: 0px auto"
          @click="saveGroup()"
        >
          حفظ المجموعة
        </button>
        <br><br>
        *اذا قام المعلم بالتبصيم مرة اخرى بعد الحضور فسوف يحسب انصراف*
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  created() {
      if(!checkPer("teachers|settings")){
          this.$router.push('/NotPermitted')
          return false;
      }
    var _g = this;
    axios
      .post(api + "/user/teachers/group", {
        jwt: _g.user.jwt,
        id: window.location.pathname.split("/edit/")[1],
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.group = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    saveGroup() {
      var checked = [],
        _g = this;
      this.$refs.days.forEach(function (a) {
        if (a.checked) {
          checked.push(a.value);
        }
      });
      if (checked.length == 0) {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار الايام",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      } else {
        if (_g.$refs.title.value == "" || _g.$refs.title.value == undefined) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "برجاء كتابة العنوان",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          if (
            this.$refs.late_date.value.replace(":", "") <=
            this.$refs.start_date.value.replace(":", "")
          ) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "يجب ان يكون وقت بداية احتساب التأخر اكبر من وقت الحضور",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          } else {
            if (
              this.$refs.ghiab_date.value.replace(":", "") <=
              this.$refs.late_date.value.replace(":", "")
            ) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "يجب ان يكون وقت بداية احتساب الغياب اكبر من وقت التأخر",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            } else {
              if (
                this.$refs.end_date.value.replace(":", "") <=
                this.$refs.start_date.value.replace(":", "")
              ) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      "يجب ان يكون وقت بداية احتساب الانصراف اكبر من وقت الحضور",
                    icon: "WarningIcon",
                    variant: "danger",
                  },
                });
              } else {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري التحميل..",
                    icon: "WarningIcon",
                    variant: "warning",
                  },
                });
                axios
                  .post(
                    api + "/user/teachers/groups/save",
                    {
                      title: this.$refs.title.value,
                      days: checked.join(","),
                      id: _g.group._id,
                      start_date: this.$refs.start_date.value,
                      late_date: this.$refs.late_date.value,
                      ghiab_date: this.$refs.ghiab_date.value,
                      end_date: this.$refs.end_date.value,
                      second_leave: this.group.second_leave,
                      jwt: _g.user.jwt,
                    }
                  )
                  .then(function (r) {
                    _g.$toast.clear();
                    if (r.data.status == 100) {
                      _g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "تم حفظ المجموعة بنجاح",
                          icon: "WarningIcon",
                          variant: "success",
                        },
                      });
                      _g.$router.push("/_teachers/groups");
                    } else {
                      _g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: r.data.response,
                          icon: "WarningIcon",
                          variant: "danger",
                        },
                      });
                    }
                  })
                  .catch(function () {
                    _g.$toast.clear();
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "حدث خطأ",
                        icon: "WarningIcon",
                        variant: "danger",
                      },
                    });
                  });
              }
            }
          }
        }
      }
    },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      group: {},
      days: [
        {
          en: "Saturday",
          ar: "السبت",
        },
        {
          en: "Sunday",
          ar: "الأحد",
        },
        {
          en: "Monday",
          ar: "الاثنين",
        },
        {
          en: "Tuesday",
          ar: "الثلاثاء",
        },
        {
          en: "Wednesday",
          ar: "الأربعاء",
        },
        {
          en: "Thursday",
          ar: "الخميس",
        },
        {
          en: "Friday",
          ar: "الجمعة",
        },
      ],
    };
  },
};
</script>

<style>
</style>
